import React from "react";
import "./base.scss";
import "./App.scss";
import { Provider } from "react-redux";
import { configureStore } from "./store/store";
import Routes from "./Routes";

export const store = configureStore({});

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
