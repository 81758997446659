import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as dashboardActions from "../actions/dashboardActions";

const connectDashboard = component => {
  const mapStateToProps = state => {
    return {
      data: { ...state.dashboardReducer, ...state.appReducer }
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      dispatch,
      actions: bindActionCreators(
        {
          ...dashboardActions
        },
        dispatch
      )
    };
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(component);
};

export default connectDashboard;
