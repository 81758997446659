import React, { useEffect, useState } from "react";
import connectDashboard from "../../connectors/connectDashboard";
import {
  IC_SORT,
  IC_BLOCK,
  IC_PAGE_PRE_DIS,
  IC_PAGE_PRE,
  IC_PAGE_NEXT_DIS,
  IC_PAGE_NEXT,
  IC_LOGIN_BTN
} from "../../const/imgCost";
import Textbox from "../../components/Textbox/Textbox";
import Dropdown from "../../components/Dropdown/Dropdown";
import RadioGroup from "../../components/RadioGroup/RadioGroup";
import Checkbox from "../../components/Checkbox/Checkbox";
import Button from "../../components/Button/Button";
import Popup from "../../components/Popup/Popup";
import { Toast } from "react-bootstrap";
import {
  popup_message,
  notification_message_required
} from "../../const/messages";

function Dashboard(props) {
  const dropdownOptions = [
    {
      text: "10",
      value: "10"
    },
    {
      text: "20",
      value: "20"
    },
    {
      text: "50",
      value: "50"
    },
    {
      text: "100",
      value: "100"
    }
  ];
  const radioOptions = [
    {
      text: "All Users",
      value: "all"
    },
    {
      text: "Disabled",
      value: "disabled"
    }
  ];
  const sortOrderObj = {
    asc: "ASC",
    desc: "DESC"
  };

  const [sortOptions, setSortOptions] = useState({
    sortBy: "username",
    sortOrder: sortOrderObj.asc
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [itemsPerPage, setItemPerPage] = useState("10");
  const [pageNo, setPageNo] = useState("1");
  const [lastVisitedPage, setLastVisitedPage] = useState("1");
  const [isNotifyAll, setIsNotifyAll] = useState(false);
  const [isNotifyRegUsers, setIsNotifyRegUsers] = useState(false);
  const [isNotifySelectedUser, setIsNotifySelectedUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupError, setPopupError] = useState("");

  const queryUserList = (searchVal, pageNoVal) => {
    let queryString = "?";
    queryString += "sortBy=" + sortOptions.sortBy;
    queryString += "&sortOrder=" + sortOptions.sortOrder;
    queryString += "&itemsPerPage=" + itemsPerPage;

    if (isDisabled) {
      queryString += "&isDisabled=" + isDisabled;
    }
    if (pageNoVal) {
      queryString += "&pageNo=" + (pageNoVal || lastVisitedPage);
    }
    if (searchVal && searchVal.trim().length > 2) {
      queryString += "&searchTerm=" + searchVal.trim().toLowerCase();
    }
    props.actions.getUserList(queryString);
  };

  useEffect(() => {
    queryUserList(searchTerm, pageNo);
  }, [sortOptions, isDisabled, itemsPerPage]);

  const handleSearch = e => {
    if (e && e.target) {
      const value = e.target.value ? e.target.value : "";

      setSearchTerm(value);
      if (!value || (value && value.trim().length > 2)) {
        queryUserList(value.trim(), 1);
        setPageNo(1);
        setSelectedUser([]);
      }
    }
  };

  const handleItemPerPage = e => {
    if (e && e.target && e.target.value) {
      const value = e.target.value.trim();
      setItemPerPage(value);
      setPageNo(1);
    }
  };

  const changePageNo = value => {
    setPageNo(value);

    if (value && value.trim().length) {
      queryUserList(searchTerm, value.trim());
    }
  };

  const handleNextPage = () => {
    const pageVal = pageNo || lastVisitedPage;
    if (pageVal < totalPages) {
      const newPage = parseInt(pageVal) + 1;
      changePageNo(newPage.toString());
    }
  };

  const handlePrevPage = () => {
    const pageVal = pageNo || lastVisitedPage;
    if (pageVal >= 2) {
      const newPage = parseInt(pageVal) - 1;
      changePageNo(newPage.toString());
    }
  };

  const handlePageNo = e => {
    if (e && e.target) {
      const value = e.target.value ? e.target.value : "";

      if (!value) {
        setLastVisitedPage(pageNo);
        setPageNo(value);
      } else if (
        value &&
        !isNaN(parseInt(value)) &&
        parseInt(value) > 0 &&
        parseInt(value) <= parseInt(totalPages)
      ) {
        setPageNo(value);

        if (value && value.trim().length) {
          queryUserList(searchTerm, value.trim());
        }
      }
    }
  };

  const handleClickOnSort = sortByValue => {
    let sortOrder = sortOrderObj.asc;

    if (sortByValue === sortOptions.sortBy) {
      if (sortOptions.sortOrder === sortOrderObj.asc) {
        sortOrder = sortOrderObj.desc;
      } else {
        sortOrder = sortOrderObj.asc;
      }
    }

    setSortOptions({
      sortBy: sortByValue,
      sortOrder: sortOrder
    });
  };

  const sendNotification = text => {
    if (text && text.trim()) {
      if (isNotifyAll) {
        notifyAllUsers(text);
        setPopupError("");
        setShowPopup(false);
      } else if (isNotifyRegUsers) {
        notifyRegUsers(text);
        setPopupError("");
        setShowPopup(false);
      } else if (isNotifySelectedUser) {
        notifySelectedUsers(text);
      }
    } else {
      setPopupError(notification_message_required);
    }
  };

  const notifyAllUsers = text => {
    const notifyUserData = {
      message: text.trim(),
      isSelectedUsers: false,
      isNotifyAllUsers: true,
      isNotifyRegisteredUsers: false
    };
    props.actions.setNotifyUsers(notifyUserData);
  };

  const notifyRegUsers = text => {
    const notifyUserData = {
      message: text.trim(),
      isSelectedUsers: false,
      isNotifyAllUsers: false,
      isNotifyRegisteredUsers: true
    };
    props.actions.setNotifyUsers(notifyUserData);
  };

  const notifySelectedUsers = text => {
    if (selectedUser.length) {
      const userId = selectedUser.join(",");
      const notifyUserData = {
        message: text.trim(),
        isSelectedUsers: true,
        isNotifyAllUsers: false,
        isNotifyRegisteredUsers: false,
        userList: userId
      };

      props.actions.setNotifyUsers(notifyUserData);
      setPopupError("");
      setShowPopup(false);
    } else {
      setPopupError(popup_message);
    }
  };

  const handleCheckChange = e => {
    const value = e.target && e.target.value ? e.target.value.toString() : "";
    const isChecked = e.target.checked;
    if (value === "all") {
      if (
        props.data.userListData &&
        props.data.userListData.data &&
        props.data.userListData.data.length
      ) {
        if (isChecked) {
          let userArr = [];
          userArr = props.data.userListData.data.map((obj, index) => {
            return obj.id ? obj.id.toString() : null;
          });
          setSelectedUser(userArr);
        } else {
          setSelectedUser([]);
        }
      }
    } else {
      if (isChecked) {
        const userArr = Array.from(selectedUser);
        userArr.push(value);
        setSelectedUser(userArr);
      } else {
        const userArr = Array.from(selectedUser);
        const index = userArr.indexOf(value);
        userArr.splice(index, 1);
        setSelectedUser(userArr);
      }
    }
  };

  const handleRadioChange = e => {
    setPageNo(1);
    setSelectedUser([]);
    if (e.target.value === "disabled") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const handleUserDisable = (userId, isDisabled) => {
    props.actions.setUserDisabled({
      userId,
      isDisabled
    });
  };

  const isFirstPage = props.data.userListData.isFirstPage;
  const isLastPage = props.data.userListData.isLastpage;
  const totalCount = props.data.userListData.totalCount;
  const totalUserCount = props.data.userListData.totalUserCount;
  const totalDevices = props.data.userListData.totalDevices;
  const totalPages = props.data.userListData.totalPages;
  const pageVal = pageNo || lastVisitedPage;
  const startOffset = pageVal ? (pageVal - 1) * itemsPerPage + 1 : 0;
  const endOffset =
    parseInt((pageVal - 1) * itemsPerPage) + parseInt(itemsPerPage);

  let tableRows = [];
  if (props.data.isLoading) {
    tableRows = (
      <tr>
        <td colSpan="4" className="loader_container">
          <div className="loader"></div>
        </td>
      </tr>
    );
  } else if (
    props.data.userListData &&
    props.data.userListData.data &&
    props.data.userListData.data.length
  ) {
    tableRows = props.data.userListData.data.map((obj, index) => {
      const objId = obj.id ? obj.id.toString() : "";
      return (
        <tr>
          <td>
            <div className="form-group">
              <Checkbox
                isChecked={selectedUser.indexOf(objId) > -1}
                id={"userRow" + obj.id}
                additionalClass=""
                selectedValue={obj.id}
                onChangeHandler={handleCheckChange}
              />
            </div>
          </td>
          <td>{obj.username || ""}</td>
          <td>{obj.email || ""}</td>
          <td className="action_ic text-center">
            {obj.rolename &&
              obj.rolename.toLowerCase() === "user" &&
              !obj.is_disabled &&
              !obj.isCalled && (
                <img
                  src={IC_BLOCK}
                  title="Disable user"
                  alt=""
                  className="disable_button pointer"
                  onClick={e => {
                    if (!obj.isCalled) {
                      obj.isCalled = true;
                      handleUserDisable(obj.id, true);
                    }
                  }}
                />
              )}
            {obj.rolename &&
              obj.rolename.toLowerCase() === "user" &&
              obj.is_disabled &&
              !obj.isCalled && (
                <img
                  title="Enable user"
                  src={IC_LOGIN_BTN}
                  alt=""
                  className="enable_button"
                  onClick={e => {
                    if (!obj.isCalled) {
                      obj.isCalled = true;
                      handleUserDisable(obj.id, false);
                    }
                  }}
                />
              )}
          </td>
        </tr>
      );
    });
  } else {
    tableRows = (
      <tr className="loader_container">
        <td colSpan="4">No Result Found</td>
      </tr>
    );
  }
  return (
    <>
      <Toast
        className="toast_style"
        onClose={() => props.actions.setToastVisibility(false)}
        show={props.data.isShowToast}
        delay={10000}
        autohide
      >
        <Toast.Body>{props.data.toastMessage}</Toast.Body>
      </Toast>
      <Popup
        show={showPopup}
        errorMessage={popupError}
        noOfUsers={
          isNotifyAll
            ? totalDevices
            : isNotifyRegUsers
            ? totalUserCount
            : isNotifySelectedUser
            ? selectedUser.length
            : ""
        }
        handleSend={notificationText => {
          sendNotification(notificationText);
        }}
        handleClose={() => {
          setIsNotifyAll(false);
          setIsNotifyRegUsers(false);
          setIsNotifySelectedUser(false);
          setPopupError("");
          setShowPopup(false);
        }}
      />
      <div className="row">
        <div className="col-lg-12">
          <h1>User Management</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tablecontent_wrapper">
            <div className="filter_wrapper">
              <div className="search_box">
                <Textbox
                  name="search"
                  placeholder="Search by name or email…"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              <div className="radio_wrapper">
                <RadioGroup
                  checked={isDisabled ? "disabled" : "all"}
                  optionsArray={radioOptions}
                  additionalClass=""
                  additionalParentClass="mr-4"
                  id="userRadio"
                  onChangeHandler={handleRadioChange}
                />
              </div>
              <div className="notification_btn">
                <div className="notify_text mr-3">Notify:</div>
                <Button
                  type="button"
                  name="notify-all"
                  text="All"
                  onClick={() => {
                    setIsNotifyAll(true);
                    setIsNotifyRegUsers(false);
                    setIsNotifySelectedUser(false);
                    setShowPopup(true);
                  }}
                  disabled={false}
                  additionalClass="send_notification mr-3"
                />
                <Button
                  type="button"
                  name="notify-reg-users"
                  text="Registered Users Only"
                  onClick={() => {
                    setIsNotifyAll(false);
                    setIsNotifyRegUsers(true);
                    setIsNotifySelectedUser(false);
                    setShowPopup(true);
                  }}
                  disabled={false}
                  additionalClass="send_notification mr-3"
                />
                <Button
                  type="button"
                  name="notify-reg-users"
                  text="Selected Users"
                  onClick={() => {
                    setIsNotifyAll(false);
                    setIsNotifyRegUsers(false);
                    setIsNotifySelectedUser(true);
                    setShowPopup(true);
                  }}
                  disabled={false}
                  additionalClass="send_notification"
                />
              </div>
            </div>
            <div className="table_wrapper">
              <table>
                <thead>
                  <tr>
                    <td>
                      <div className="form-group">
                        <Checkbox
                          isChecked={
                            props.data.userListData &&
                            props.data.userListData.data &&
                            props.data.userListData.data.length > 0
                              ? parseInt(selectedUser.length) ===
                                parseInt(props.data.userListData.data.length)
                              : false
                          }
                          id="all-users"
                          additionalClass=""
                          selectedValue="all"
                          onChangeHandler={handleCheckChange}
                        />
                      </div>
                    </td>
                    <td className="user_name">
                      User Name{" "}
                      <span className="shorting_ic">
                        <a
                          href="javascript:void"
                          onClick={() => {
                            handleClickOnSort("username");
                          }}
                        >
                          <img src={IC_SORT} alt="" />
                        </a>
                      </span>
                    </td>
                    <td className="email_address">
                      Email Address{" "}
                      <span className="shorting_ic">
                        <a
                          href="javascript:void"
                          onClick={() => {
                            handleClickOnSort("email");
                          }}
                        >
                          <img src={IC_SORT} alt="" />
                        </a>
                      </span>
                    </td>
                    <td className="text-center">Action</td>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          </div>
          <div>
            <div className="pagination_wrapper">
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    Total: {totalCount} results, Current view range{" "}
                    {startOffset}-{endOffset}
                  </div>
                  <div className="mt-3">
                    <span>Show </span>{" "}
                    <span>
                      <Dropdown
                        optionsArray={dropdownOptions}
                        additionalClass="show_number pagination_number_box"
                        selectedValue={itemsPerPage}
                        onChangeHandler={handleItemPerPage}
                      />
                    </span>{" "}
                  </div>
                </div>
                <div className="col-lg-6 text-right">
                  <div>
                    <span className="mr-5">
                      <img
                        onClick={handlePrevPage}
                        src={isFirstPage ? IC_PAGE_PRE_DIS : IC_PAGE_PRE}
                        alt=""
                      />{" "}
                      Previous
                    </span>
                    <span>
                      {" "}
                      Next{" "}
                      <img
                        onClick={handleNextPage}
                        src={isLastPage ? IC_PAGE_NEXT_DIS : IC_PAGE_NEXT}
                        alt=""
                      />
                    </span>{" "}
                  </div>
                  <div className="mt-3">
                    <span>Go to page </span>{" "}
                    <span>
                      <Textbox
                        name="page_number"
                        value={pageNo}
                        onChange={handlePageNo}
                        additionalClass="page_number"
                      />{" "}
                      of {totalPages}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connectDashboard(Dashboard);
