import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import connectComponent from "./connectors/connectComponent";
import Login from "./screens/Login/Login";
import Dashboard from "./screens/Dashboard/Dashboard";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
// import Messages from "./screens/Messages/Messages";

const Routes = props => {
  let isUserLoggedIn = false;
  if (
    (props.data.appReducer && props.data.appReducer.accessToken) ||
    localStorage.getItem("accessToken")
  ) {
    isUserLoggedIn = true;
  } else {
    isUserLoggedIn = false;
  }
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    props.actions.handleLogout();
  };

  return (
    <Router>
      <Switch>
        <Route
          path="/dashboard"
          render={() => {
            if (isUserLoggedIn) {
              return (
                <>
                  <Header {...props} handleLogout={handleLogout} />
                  <div className="content_wrapper">
                    <Sidebar />
                    <div className="page_content">
                      <Dashboard />
                    </div>
                  </div>
                </>
              );
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/forgot-password"
          exact
          render={props => {
            return <ForgotPassword {...props} />;
          }}
        />
        <Route
          path="/"
          exact
          render={props => {
            if (isUserLoggedIn) {
              return <Redirect to="/dashboard" />;
            } else {
              return <Login {...props} />;
            }
          }}
        />
        <Route
          render={props => {
            if (isUserLoggedIn) {
              return <Redirect to="/dashboard" />;
            } else {
              return <Login {...props} />;
            }
          }}
        />
      </Switch>
    </Router>
  );
};

export default connectComponent(Routes);
