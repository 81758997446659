import React from "react";

import "./Sidebar.scss";
import { IC_PROFILE } from "../../const/imgCost";

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="nav">
        <div className="nav_item ">
          <div className="active"></div>
          <span className="item">
            <a className="sidebar_link">
              <img src={IC_PROFILE} className="mr-3" alt="" />
              <span>User Management</span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
