//check is individual fields are valid
export const validateField = (
  fieldName,
  errorState,
  usersObj,
  isCheckValidity
) => {
  let err = Object.assign({}, errorState);
  let userObject = Object.assign({}, usersObj);
  let isFieldValid = false;

  switch (fieldName) {
    case "name":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Name is required !";
      break;

    case "confirm_password":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Confirm Password is required !";
      if (userObject[fieldName].length > 0) {
        isFieldValid =
          userObject["confirm_password"] === userObject["user_password"];
        err[fieldName] = isFieldValid ? "" : "Passwords do not match !";
      }
      if (isFieldValid && isCheckValidity) {
        isFieldValid = validPassword(userObject[fieldName]);
        err[fieldName] = isFieldValid
          ? ""
          : "Password must contain Minimum eight characters, at least one uppercase and lowercase character, one number and one special character";
      }
      break;

    case "user_password":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Password is required !";

      if (userObject[fieldName].length > 0) {
        if (userObject[fieldName].length < 8) {
          err[fieldName] = "Password must be at least 8 characters long";
        } else if (!/\d/.test(userObject[fieldName])) {
          err[fieldName] = "Password must contain a digit";
        } else if (!/[@#$%!]/.test(userObject[fieldName])) {
          err[fieldName] = "Password must contain special character: @#$%!";
        } else if (!/(?!.*[\s])/.test(userObject[fieldName])) {
          err[fieldName] = "Password must not contain space";
        } else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(userObject[fieldName])) {
          err[fieldName] =
            "Password must contain atleast one uppercase and lowercase letter";
        } else {
          err[fieldName] = "";
        }
      }
      break;

    case "new_username":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Username is required !";
      if (userObject[fieldName].length > 0) {
        let reg = /^[a-zA-Z0-9]{8,}$/;
        isFieldValid = reg.test(userObject[fieldName]);
        err[fieldName] = isFieldValid
          ? ""
          : "Username should be alphanumeric with minimum 8 characters.";
      }
      break;

    case "email":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Email is required !";
      if (isFieldValid && isCheckValidity) {
        isFieldValid = validEmail(userObject[fieldName]);
        err[fieldName] = isFieldValid ? "" : "Email is invalid !";
      }
      break;

    case "password":
      isFieldValid = userObject[fieldName].length > 0;
      err[fieldName] = isFieldValid ? "" : "Password is required !";
      if (isFieldValid && isCheckValidity) {
        isFieldValid = validPassword(userObject[fieldName]);
        err[fieldName] = isFieldValid
          ? ""
          : "Password must contain Minimum eight characters, at least one uppercase and lowercase character, one letter and one number";
      }
      break;
    default:
      break;
  }
  return err;
};

const validEmail = email => {
  let re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  return re.test(String(email).toLowerCase());
};

const validPassword = pass => {
  let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(String(pass));
};

//to check if all fields of a form is empty so as to disable the button
export const validateAllFieldsEmpty = (allFields, allErrors) => {
  let fieldObj = {};
  let errorObj = {};
  for (let [key, value] of Object.entries(allFields)) {
    fieldObj[key] = value.length === 0;
  }
  for (let [key, value] of Object.entries(allErrors)) {
    errorObj[key] = value.length !== 0;
  }
  const isDisabled =
    Object.keys(fieldObj).some(x => fieldObj[x]) ||
    Object.keys(errorObj).some(x => errorObj[x]);
  return isDisabled;
};
