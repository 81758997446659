const IC_BLOCK = require("../assets/images/block_ic.svg");
const IC_MAIL = require("../assets/images/ic_mail.svg");
const IC_PASS = require("../assets/images/ic_pass.svg");
const IC_POPUP_CLOSE = require("../assets/images/ic_popup_close.svg");
const IC_PROFILE = require("../assets/images/ic_profile.svg");
const IC_SORT = require("../assets/images/ic_sorting.svg");
const IC_LOGIN_BG_1 = require("../assets/images/login_bg_1.svg");
const IC_LOGIN_BG = require("../assets/images/login_bg.svg");
const IC_LOGIN_BTN = require("../assets/images/login_btn.svg");
const IMG_LOGIN_LOG = require("../assets/images/login_log.png");
const IC_LOGIN_LOG = require("../assets/images/login_log.svg");
const IC_HEADER_LOGO = require("../assets/images/lowerscript_headerlogo.svg");
const IC_PAGE_NEXT = require("../assets/images/pagination_next_active.svg");
const IC_PAGE_NEXT_DIS = require("../assets/images/pagination_next_dis.svg");
const IC_PAGE_PRE = require("../assets/images/pagination_pre_active.svg");
const IC_PAGE_PRE_DIS = require("../assets/images/pagination_pre_dis.svg");

export {
    IC_BLOCK,
    IC_MAIL,
    IC_PASS,
    IC_POPUP_CLOSE,
    IC_PROFILE,
    IC_SORT,
    IC_LOGIN_BG_1,
    IC_LOGIN_BG,
    IC_LOGIN_BTN,
    IMG_LOGIN_LOG,
    IC_LOGIN_LOG,
    IC_HEADER_LOGO,
    IC_PAGE_NEXT,
    IC_PAGE_NEXT_DIS,
    IC_PAGE_PRE,
    IC_PAGE_PRE_DIS
};