import React from "react";
import PropTypes from "prop-types";

const Button = props => {
  return (
    <button
      className={`${props.additionalClass}`}
      onClick={props.onClick}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
};

export default Button;

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  additionalClass: PropTypes.string
};
