import { combineReducers } from "redux";
import {appReducer} from './appReducer';
import {loginReducer} from './loginReducer';
import {dashboardReducer} from './dashboardReducer';

const rootReducer = combineReducers({
  appReducer,
  loginReducer,
  dashboardReducer
});

export default rootReducer