import React from "react";
import PropTypes from "prop-types";
import "./Password.css";

const Password = props => {
  return (
    <>
      <input
        name={props.name}
        type="password"
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        className={props.additionalClass}
      />
      {props.error && <label className="error_message">{props.error}</label>}
    </>
  );
};

export default Password;

Password.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  additionalClass: PropTypes.string
};
