import actions from "../actions/actions";

const initialState = {
  accessToken: "",
  authorizationData: {},
  authorizationError: "",
  refreshToken: "",
  selectedOrganizationObject: {},
  isError: false,
  errorMessage: ""
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_LOGIN_ERROR:
      return {
        ...state,
        isError: false
      };
    case actions.REDIRECT_USER:
      return {
        ...state,
        accessToken: ""
      };
    case actions.ORGANIZATION_SELECTED:
      return {
        ...state,
        selectedOrganizationObject: action.data
      };
    case actions.GET_AUTH_TOKEN:
      let accessToken = "",
        isError = false,
        errorMessage = "";
      if (
        action.data &&
        action.data.data &&
        action.data.data.body &&
        action.data.data.statusCode &&
        action.data.data.statusCode == "200"
      ) {
        if (
          action.data.data.body.userType &&
          action.data.data.body.userType.toLowerCase() !== "admin"
        ) {
          isError = true;
          errorMessage = "You are not authorised to login";
        } else {
          accessToken =
            action.data && action.data.data && action.data.data.body
              ? action.data.data.body.access_token
              : "";
          const userName =
            action.data && action.data.data && action.data.data.body
              ? action.data.data.body.userName
              : "";

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("userName", userName);
        }
      }

      return {
        ...state,
        isError: isError
          ? true
          : action.data && action.data.data && action.data.data.statusCode
          ? action.data.data.statusCode != "200"
          : true,
        errorMessage: errorMessage
          ? errorMessage
          : action.data && action.data.data && action.data.data.body
          ? action.data.data.body || errorMessage
          : "",
        authorizationData: action.data.data ? action.data.data.body : null,
        accessToken: accessToken,
        refreshToken:
          action.data && action.data.data && action.data.data.body
            ? action.data.data.body.refresh_token
            : ""
      };
    case actions.AUTH_ERROR:
      return {
        ...state,
        authorizationError: action.data || ""
      };
    default:
      return state;
  }
};
