import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const connectHeader = (component) => {
  const mapStateToProps = (state) => {
    return {
      data: state.appReducer,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      actions: bindActionCreators({}, dispatch),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default connectHeader;
