import actions from "../actions/actions";

const initialState = {
  otpData: {},
  otpError: {},
  verifyErr: {},
  resetData: {},
  resetDataErr: {},
  verifyData: {},
  refreshToken: "",
  isForgotError: false,
  errorMsg: "",
  isOTPSuccess: false,
  isVerifyError: false,
  isVerifySuccess: false,
  isResetErr: false,
  isResetSuccess: false
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_VERIFY_FLAG:
      return {
        ...state,
        isVerifyError: false
      };
    case actions.SET_FORGOT_FLAG:
      return {
        ...state,
        isForgotError: false
      };
    case actions.SET_RESET_FLAG:
      return {
        ...state,
        isResetErr: false
      };
    case actions.GET_OTP_ERROR:
      return {
        ...state,
        otpError: action.data && action.data.data ? action.data.data : {}
      };
    case actions.GET_OTP:
      let isError = false,
        errorMessage = "";
      if (
        action.data.data.body &&
        action.data.data.body.userType &&
        action.data.data.body.userType.toLowerCase() !== "admin"
      ) {
        isError = true;
        errorMessage = "You are not authorised to reset password";
      }
      return {
        ...state,
        isForgotError: isError
          ? true
          : action.data && action.data.data
          ? action.data.data.statusCode != 200
          : null,
        isOTPSuccess: isError
          ? false
          : action.data && action.data.data
          ? action.data.data.statusCode == 200
          : false,
        errorMsg: errorMessage
          ? errorMessage
          : action.data && action.data.data
          ? action.data.data.body
          : null,
        otpData: action.data && action.data.data ? action.data.data : null
      };
    case actions.VERIFY_OTP:
      return {
        ...state,
        isVerifyError:
          action.data && action.data.data
            ? action.data.data.statusCode != 200
            : null,
        isVerifySuccess:
          action.data && action.data.data
            ? action.data.data.statusCode == 200
            : false,
        errorMsg:
          action.data && action.data.data ? action.data.data.body : null,
        verifyData: action.data && action.data.data ? action.data.data : null,
        resetToken:
          action.data && action.data.data && action.data.data.body
            ? action.data.data.body.access_token
            : null
      };
    case actions.VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyErr: action.data && action.data.data ? action.data.data : {}
      };
    case actions.RESET_PWD:
      return {
        ...state,
        isResetErr:
          action.data && action.data.data
            ? action.data.data.statusCode != 200
            : null,
        isResetSuccess:
          action.data && action.data.data
            ? action.data.data.statusCode == 200
            : false,
        errorMsg:
          action.data && action.data.data ? action.data.data.body : null,
        resetData: action.data && action.data.data ? action.data.data : null
      };
    case actions.RESET_PWD_ERR:
      return {
        ...state,
        resetDataErr: action.data && action.data.data ? action.data.data : null
      };
    default:
      return state;
  }
};
