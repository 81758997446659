import React from "react";
import PropTypes from "prop-types";

const RadioGroup = props => {
  const getRadioInput = (obj, index) => {
    if (props.checked === obj.value) {
      return (
        <input
          type="radio"
          id={props.id + index}
          name="radio-group"
          checked
          value={obj.value}
          onChange={props.onChangeHandler}
          className={props.additionalClass}
        />
      );
    } else {
      return (
        <input
          type="radio"
          id={props.id + index}
          name="radio-group"
          value={obj.value}
          onChange={props.onChangeHandler}
          className={props.additionalClass}
        />
      );
    }
  };

  let options = props.optionsArray.map((obj, index) => {
    return (
      <div className={props.additionalParentClass}>
        {getRadioInput(obj, index)}
        <label htmlFor={props.id + index}>{obj.text}</label>
      </div>
    );
  });

  return <>{options}</>;
};

export default RadioGroup;

RadioGroup.propTypes = {
  checked: PropTypes.bool,
  optionsArray: PropTypes.array,
  additionalClass: PropTypes.string,
  additionalParentClass: PropTypes.string,
  id: PropTypes.string,
  onChangeHandler: PropTypes.func
};
