const actions = {
  GET_AUTH_TOKEN: "getAuthToken",
  GET_USER_LIST: "getUserList",
  USER_LIST_ERROR: "userListError",
  AUTH_ERROR: "authError",
  SET_USER_DISABLED: "setUserDisabled",
  SET_USER_DISABLE_ERROR: "setUserDisableError",
  NOTIFY_ERR: "notifyErr",
  NOTIFY: "notify",
  SET_LOADING: "isLoading",
  REMOVE_LOADING: "isRemoveLoader",
  SHOW_TOAST: "show_toast",
  REDIRECT_USER: "redirect_user",
  GET_OTP: "getOtp",
  GET_OTP_ERROR: "getOtpError",
  VERIFY_OTP: "verifyOtp",
  VERIFY_OTP_ERROR: "verifyOtpError",
  RESET_PWD: "resetPassword",
  RESET_PWD_ERR: "resetPasswordError",
  SET_VERIFY_FLAG: "resetVerifyError",
  SET_FORGOT_FLAG: "resetForgotError",
  SET_RESET_FLAG: "resetResetPasswordError",
  RESET_LOGIN_ERROR: "resetLoginError"
};

export default actions;
