import axios from "axios";
import apiEndPoints from "./apiEndPoints";
import { store } from "../App";
import actions from "../actions/actions";

const defaultOptions = {
  baseURL: "https://ytatmx5cu4.execute-api.us-west-2.amazonaws.com/Production",
  headers: {
    "Content-Type": "application/json"
  }
};

let axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(config => {
  let url = config.url.split("?");
  if (
    url[0] === apiEndPoints.getUserList ||
    url[0] === apiEndPoints.setUserDisabled ||
    url[0] === apiEndPoints.notify
  ) {
    const accessToken = localStorage.getItem("accessToken");
    config.headers["x-access-token"] = `${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      !error.response ||
      !error.response.status ||
      error.response.status === 401 ||
      error.response.status === 403
    ) {
      // Access token expired
      localStorage.removeItem("accessToken");
      store.dispatch({
        type: actions.REDIRECT_USER
      });
    } else if (error.response.status === 404) {
      // redirect to 404 page
    } else if (error.response.status === 500) {
      // handle server error
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
