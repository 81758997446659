import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginActions from "../actions/loginActions";

const connectLogin = component => {
  const mapStateToProps = state => {
    return {
      data: { ...state.loginReducer, ...state.appReducer }
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      dispatch,
      actions: bindActionCreators(
        {
          ...loginActions
        },
        dispatch
      )
    };
  };
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(component);
};

export default connectLogin;
