const apiEndPoints = {
  LOGIN: "/login",
  getUserList: "/getusers",
  setUserDisabled: "/setuserdisabled",
  notify: "/notifyusers",
  getOtp: "/forgotpassword",
  verifyOtp: "/verifyotp",
  resetPassword: "/resetpassword"
};

export default apiEndPoints;
