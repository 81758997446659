import axiosInstance from "../common/apiHandler";
import apiEndPoints from "../common/apiEndPoints";
import actions from "./actions";

export const getUserList = queryParams => {
  return dispatch => {
    dispatch({
      type: actions.SET_LOADING,
      data: true
    });
    axiosInstance
      .get(apiEndPoints.getUserList + queryParams)
      .then(data => {
        dispatch({
          type: actions.SET_LOADING,
          data: false
        });
        dispatch({
          type: actions.GET_USER_LIST,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.SET_LOADING,
          data: false
        });
        dispatch({
          type: actions.USER_LIST_ERROR,
          data: error
        });
      });
  };
};

export const setUserDisabled = dataObj => {
  return dispatch => {
    axiosInstance
      .post(apiEndPoints.setUserDisabled, dataObj)
      .then(data => {
        dispatch({
          type: actions.SHOW_TOAST,
          data: {
            isVisible: true,
            message: dataObj.isDisabled
              ? "User disabled successfully"
              : "User enabled successfully"
          }
        });
        dispatch({
          type: actions.SET_USER_DISABLED,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.SHOW_TOAST,
          data: {
            isVisible: true,
            message: "Some error occured"
          }
        });
        dispatch({
          type: actions.SET_USER_DISABLE_ERROR,
          data: error
        });
      });
  };
};

export const setToastVisibility = isVisible => {
  return dispatch => {
    dispatch({
      type: actions.SHOW_TOAST,
      data: {
        isVisible: isVisible,
        message: ""
      }
    });
  };
};

export const setNotifyUsers = dataObj => {
  return dispatch => {
    axiosInstance
      .post(apiEndPoints.notify, dataObj)
      .then(data => {
        dispatch({
          type: actions.SHOW_TOAST,
          data: {
            isVisible: true,
            message: "Notification sent successfully"
          }
        });
        dispatch({
          type: actions.NOTIFY,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.SHOW_TOAST,
          data: {
            isVisible: true,
            message: "Some error occured"
          }
        });
        dispatch({
          type: actions.NOTIFY_ERR,
          data: error
        });
      });
  };
};
