import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const Dropdown = props => {
  let options = props.optionsArray.map(obj => {
    return <option value={obj.value}>{obj.text}</option>;
  });

  return (
    // <Form.Group
    //   controlId="exampleForm.SelectCustom"
    // >
      <Form.Control as="select" value={props.selectedValue} onChange={props.onChangeHandler} className={props.additionalClass}>
        {options}
      </Form.Control>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  optionsArray: PropTypes.array,
  additionalClass: PropTypes.string,
  selectedValue: PropTypes.string,
  onChangeHandler: PropTypes.func
};
