import React from "react";
import PropTypes from "prop-types";
import "./Textbox.css";

const Textbox = props => {
  return (
    <>
      <input
        name={props.name || ""}
        type="text"
        placeholder={props.placeholder || ""}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        className={props.additionalClass}
      />
      {props.error && <label className="error_message">{props.error}</label>}
    </>
  );
};

export default Textbox;

Textbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  additionalClass: PropTypes.string
};
