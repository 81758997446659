import React, { useState } from "react";

import Textbox from "../../components/Textbox/Textbox";
import Password from "../../components/Password/Password";
import { validateField } from "../../common/utility";
import connectLogin from "../../connectors/connectLogin";
import {
  IC_LOGIN_LOG,
  IC_MAIL,
  IC_PASS,
  IC_LOGIN_BTN
} from "../../const/imgCost";

export const Login = props => {
  const [user, setUser] = useState({ email: "" });
  const [errors, setErrors] = useState({ email: "" });
  const [otpError, setOtpError] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState({ password: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    confirm_password: ""
  });
  const [passwordErrors, setPasswordErrors] = useState({ password: "" });
  const [confirmPasswordError, setConfirmPasswordErrors] = useState({
    confirm_password: ""
  });

  const onHandleChange = event => {
    props.actions.resetForgotErrFlag();

    const name = event.target.name;
    const value =
      event.target && event.target.value ? event.target.value.trim() : "";
    let userData = Object.assign({}, user);
    userData[name] = value;
    setUser(userData);
    if (name === "email") {
      setErrors(validateField(name, errors, userData));
    }
  };

  const onHandleForgot = () => {
    const error = validateField("email", errors, user, true);

    if (user.email && !error.email) {
      const requestObject = {
        email: user.email
      };
      props.actions.getOtp(requestObject);
    } else {
      setErrors(error);
    }
  };

  const onChangeOtp = event => {
    props.actions.resetVerifyErrFlag();

    setOtp(event.target.value);
    const otpVal =
      event.target && event.target.value ? event.target.value.trim() : "";
    if (otpVal) {
      setOtpError("");
    } else {
      setOtpError("Please enter a security code");
    }
  };

  const onHandleVerifyOtp = () => {
    if (otp && otp.trim()) {
      const otpVal = otp.trim();
      if (otpVal.length < 4) {
        setOtpError("Security code must contain 4 digits");
      } else if (isNaN(parseInt(otpVal))) {
        setOtpError("Please enter a valid security code");
      } else {
        props.actions.verifyOtp({
          email: user.email,
          otp: otpVal
        });
      }
    } else {
      setOtpError("Please enter a security code");
    }
  };

  const onHandleChangePassword = event => {
    props.actions.resetResetErrFlag();

    const name = event.target.name;
    const value =
      event.target && event.target.value ? event.target.value.trim() : "";
    let data = {};
    data[name] = value;

    if (name === "password") {
      setPassword(data);
      setPasswordErrors(validateField(name, passwordErrors, data));
    } else if (name === "confirm_password") {
      // user_password is to compare both passwords
      let dataObj = Object.assign({}, data);
      dataObj["user_password"] = password.password;

      setConfirmPassword(data);
      setConfirmPasswordErrors(
        validateField(name, confirmPasswordError, dataObj)
      );
    }
  };

  const handleResetPassword = () => {
    let data = Object.assign({}, confirmPassword);
    data["user_password"] = password.password;

    const validatePassword = validateField(
      "password",
      passwordErrors,
      password
    );
    const validationObj = validateField(
      "confirm_password",
      confirmPasswordError,
      data,
      true
    );
    if (validatePassword.password || validationObj.confirm_password) {
      setPasswordErrors(validatePassword);
      setConfirmPasswordErrors(validationObj);
    } else {
      const passVal = password.password.trim();
      props.actions.resetPassword(
        {
          email: user.email,
          password: passVal
        },
        props.data.resetToken
      );
    }
  };

  return (
    <div className="login_background">
      <div className="login_wrapper">
        <div className="login_area">
          <div className="Logo_area">
            <img src={IC_LOGIN_LOG} alt="" />
          </div>
          <div className="form">
            {!props.data.isOTPSuccess && (
              <>
                <div className="form_group">
                  <div className="form-ele_wrapper">
                    <span>
                      <img src={IC_MAIL} alt="" />
                    </span>
                    <span>
                      <Textbox
                        name="email"
                        placeholder="Email address"
                        value={user.email}
                        onChange={e => onHandleChange(e)}
                        additionalClass="login"
                      />
                    </span>
                  </div>
                  {errors.email && (
                    <span className="error_message">{errors.email}</span>
                  )}
                  {props.data.isForgotError && (
                    <span className="error_message">{props.data.errorMsg}</span>
                  )}
                </div>
                <div className="form_group">
                  <div className="login_btn">
                    <span className="login_txt">
                      <span className="login_txt">Send Security Code</span>
                    </span>
                    <span>
                      <a href="javascript:void" onClick={onHandleForgot}>
                        <img
                          src={IC_LOGIN_BTN}
                          alt=""
                          className={"login_img"}
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </>
            )}

            {props.data.isOTPSuccess && !props.data.isVerifySuccess && (
              <>
                <div className="form_group">
                  <div className="form-ele_wrapper">
                    <span>
                      <img src={IC_PASS} alt="" />
                    </span>
                    <span>
                      <Textbox
                        name="otp"
                        placeholder="Please enter security code"
                        value={otp}
                        onChange={onChangeOtp}
                        additionalClass="login"
                      />
                    </span>
                  </div>
                  {otpError && (
                    <span className="error_message">{otpError}</span>
                  )}
                  {props.data.isVerifyError && (
                    <span className="error_message">{props.data.errorMsg}</span>
                  )}
                </div>

                <div className="form_group">
                  <div className="login_btn">
                    <span className="login_txt">
                      <span className="login_txt">Verify Security Code</span>
                    </span>
                    <span>
                      <a href="javascript:void" onClick={onHandleVerifyOtp}>
                        <img
                          src={IC_LOGIN_BTN}
                          alt=""
                          className={"login_img"}
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </>
            )}

            {props.data.isVerifySuccess && !props.data.isResetSuccess && (
              <>
                <div className="form_group">
                  <div className="form-ele_wrapper">
                    <span>
                      <img src={IC_PASS} alt="" />
                    </span>
                    <span>
                      <Password
                        name="password"
                        placeholder="Password"
                        value={password.password}
                        onChange={onHandleChangePassword}
                        additionalClass="login"
                      />
                    </span>
                  </div>
                  {passwordErrors.password && (
                    <span className="error_message">
                      {passwordErrors.password}
                    </span>
                  )}
                </div>
                <div className="form_group">
                  <div className="form-ele_wrapper">
                    <span>
                      <img src={IC_PASS} alt="" />
                    </span>
                    <span>
                      <Password
                        name="confirm_password"
                        placeholder="Confirm Password"
                        value={confirmPassword.confirm_password}
                        onChange={onHandleChangePassword}
                        additionalClass="login"
                      />
                    </span>
                  </div>
                  {confirmPasswordError.confirm_password && (
                    <span className="error_message">
                      {confirmPasswordError.confirm_password}
                    </span>
                  )}
                  {props.data.isResetErr && (
                    <span className="error_message">{props.data.errorMsg}</span>
                  )}
                </div>

                <div className="form_group">
                  <div className="login_btn">
                    <span className="login_txt">
                      <span className="login_txt">Reset Password</span>
                    </span>
                    <span>
                      <a href="javascript:void" onClick={handleResetPassword}>
                        <img
                          src={IC_LOGIN_BTN}
                          alt=""
                          className={"login_img"}
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </>
            )}
            {props.data.isResetSuccess && (
              <>
                <div className="form_group">
                  <div className="login_btn">
                    <span className="login_txt">
                      <span className="login_txt">Password Reset Successful!</span>
                    </span>
                    <div>
                      <a href="/" className="login_link">
                        Go to Login
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connectLogin(Login);
