import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const Popup = props => {
  const [content, setContent] = useState("");
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose();
        }}
        onExiting={() => {
          setContent("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Send Notification</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="popup_textarea"
            placeholder="Enter a notification"
            value={content}
            onChange={e => {
              if (e && e.target) {
                const value = e.target.value;
                setContent(value);
              }
            }}
          />
          <div className="font-14">
            You are about to send notification to {props.noOfUsers} users.
          </div>
          {props.errorMessage && (
            <span className="validation_error">{props.errorMessage}</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              props.handleSend(content);
            }}
            className="send_notification"
          >
            Send Notification
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Popup;

Popup.propTypes = {
  noOfUsers: PropTypes.string,
  show: PropTypes.bool,
  additionalClass: PropTypes.string,
  handleSend: PropTypes.func,
  handleClose: PropTypes.func,
  errorMessage: PropTypes.string
};
