import React, { useState } from "react";

import Textbox from "../../components/Textbox/Textbox";
import Password from "../../components/Password/Password";
import { validateField } from "../../common/utility";
import connectLogin from "../../connectors/connectLogin";
import {
  IC_LOGIN_LOG,
  IC_MAIL,
  IC_PASS,
  IC_LOGIN_BTN
} from "../../const/imgCost";

export const Login = props => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "" });
  const [errorsPassword, setErrorsPassword] = useState({ password: "" });

  const onHandleChange = event => {
    props.actions.resetLoginErrFlag();

    const name = event.target.name;
    const value =
      event.target && event.target.value ? event.target.value.trim() : "";
    let userData = Object.assign({}, user);
    userData[name] = value;
    setUser(userData);
    if (name === "email") {
      setErrors(validateField(name, errors, userData));
    } else {
      setErrorsPassword(validateField(name, errorsPassword, userData));
    }
  };

  const onLoginHandler = () => {
    if (user.email && user.password) {
      const requestObject = {
        email: user.email,
        password: user.password
      };
      props.actions.getAuthToken(requestObject);
    } else {
      setErrors(validateField("email", errors, user));
      setErrorsPassword(validateField("password", errorsPassword, user));
    }
  };

  return (
    <div className="login_background">
      <div className="login_wrapper">
        <div className="login_area">
          <div className="Logo_area">
            <img src={IC_LOGIN_LOG} alt="" />
          </div>
          <div className="form">
            <div className="form_group">
              <div className="form-ele_wrapper">
                <span>
                  <img src={IC_MAIL} alt="" />
                </span>
                <span>
                  <Textbox
                    name="email"
                    placeholder="Email address"
                    value={user.email}
                    onChange={e => onHandleChange(e)}
                    additionalClass="login"
                  />
                </span>
              </div>
              {errors.email && (
                <span className="error_message">{errors.email}</span>
              )}
            </div>
            <div className="form_group">
              <div className="form-ele_wrapper">
                <span>
                  <img src={IC_PASS} />
                </span>
                <span>
                  <Password
                    name="password"
                    placeholder="Password"
                    value={user.password}
                    onChange={e => onHandleChange(e)}
                    additionalClass="login width_65"
                  />
                </span>
                <span className="forgot_pass">
                  <a href="/forgot-password">Forgot Password?</a>
                </span>
              </div>
              {errorsPassword.password && (
                <span className="error_message">{errorsPassword.password}</span>
              )}
              {props.data.isError && (
                <span className="error_message">{props.data.errorMessage}</span>
              )}
            </div>
            <div className="form_group">
              <div className="login_btn">
                <span className="login_txt">
                  <span className="login_txt">SIGN IN</span>
                </span>
                <span>
                  <a href="javascript:void" onClick={onLoginHandler}>
                    <img src={IC_LOGIN_BTN} alt="" className={"login_img"} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connectLogin(Login);
