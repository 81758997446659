import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import * as appActions from '../actions/appActions'

const connectComponent = (component) => {
  const mapStateToProps = (state) => {
    return {
      data: state
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      actions: bindActionCreators({
          ...appActions
        },
        dispatch
      )
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(component)
}

export default connectComponent