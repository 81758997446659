import React from "react";
import { Dropdown } from "react-bootstrap";
import { IC_HEADER_LOGO } from "../../const/imgCost";
import "./Header.scss";
import connectHeader from "../../connectors/connectHeader";

function Header(props) {
  const userName = localStorage.getItem("userName");
  const userInitial = userName && userName[0] ? userName[0].toUpperCase() : "A";
  return (
    <div className="fix_header">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-6">
            <div className="header_log">
              <img src={IC_HEADER_LOGO} alt="" />
            </div>
          </div>
          <div className="col-lg-6 user_wrapper">
            <Dropdown>
              <Dropdown.Toggle
                id="profile-dropdown"
                className="profile_dropdown"
              >
                <span className="mr-1"> Hi, {userName}</span>
                <span className="user_img">{userInitial}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="profile_dropdown_menu">
                <Dropdown.Item
                  href="javascript:void"
                  onClick={props.handleLogout}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connectHeader(Header);
