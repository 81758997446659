import axiosInstance from "../common/apiHandler";
import apiEndPoints from "../common/apiEndPoints";
import actions from "./actions";

export const getAuthToken = dataObj => {
  return dispatch => {
    axiosInstance
      .post(apiEndPoints.LOGIN, dataObj)
      .then(data => {
        dispatch({
          type: actions.GET_AUTH_TOKEN,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.AUTH_ERROR,
          data: error
        });
      });
  };
};

export const getOtp = dataObj => {
  return dispatch => {
    axiosInstance
      .post(apiEndPoints.getOtp, dataObj)
      .then(data => {
        dispatch({
          type: actions.GET_OTP,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.GET_OTP_ERROR,
          data: error
        });
      });
  };
};

export const verifyOtp = dataObj => {
  return dispatch => {
    axiosInstance
      .post(apiEndPoints.verifyOtp, dataObj)
      .then(data => {
        dispatch({
          type: actions.VERIFY_OTP,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.VERIFY_OTP_ERROR,
          data: error
        });
      });
  };
};

export const resetPassword = (dataObj, resetToken) => {
  return dispatch => {
    const headers = {
      "x-access-token": resetToken
    };

    axiosInstance
      .post(apiEndPoints.resetPassword, dataObj, {
        headers: headers
      })
      .then(data => {
        dispatch({
          type: actions.RESET_PWD,
          data: data
        });
      })
      .catch(error => {
        dispatch({
          type: actions.RESET_PWD_ERR,
          data: error
        });
      });
  };
};

export const resetForgotErrFlag = () => {
  return dispatch => {
    dispatch({
      type: actions.SET_FORGOT_FLAG
    });
  };
};

export const resetVerifyErrFlag = () => {
  return dispatch => {
    dispatch({
      type: actions.SET_VERIFY_FLAG
    });
  };
};

export const resetResetErrFlag = () => {
  return dispatch => {
    dispatch({
      type: actions.SET_RESET_FLAG
    });
  };
};

export const resetLoginErrFlag = () => {
  return dispatch => {
    dispatch({
      type: actions.RESET_LOGIN_ERROR
    });
  };
};
