import actions from "../actions/actions";

const initialState = {
  userListData: {},
  userListError: {},
  isLoading: false,
  isShowToast: false,
  toastMessage: ""
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.data
      };
    case actions.GET_USER_LIST:
      return {
        ...state,
        userListData: action.data && action.data.data ? action.data.data : {}
      };
    case actions.USER_LIST_ERROR:
      return {
        ...state,
        userListError: {}
      };
    case actions.SET_USER_DISABLED:
      return {
        ...state,
        userDisabled: action.data && action.data.data ? action.data.data : {}
      };
    case actions.SET_USER_DISABLE_ERROR:
      return {
        ...state,
        userDisabledErr: {}
      };
    case actions.NOTIFY:
      return {
        ...state,
        userNotify: {}
      };
    case actions.NOTIFY_ERR:
      return {
        ...state,
        userNotifyErr: {}
      };
    case actions.SHOW_TOAST:
      return {
        ...state,
        isShowToast: action.data.isVisible,
        toastMessage: action.data.message
      };

    default:
      return state;
  }
};
