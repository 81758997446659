import React from "react";
import PropTypes from "prop-types";

const Checkbox = props => {
  // let options = props.optionsArray.map(obj => {
  //   return <option value={obj.value}>{obj.text}</option>;
  // });

  return (
    <>
      <input
        checked={props.isChecked}
        type="checkbox"
        id={props.id}
        value={props.selectedValue}
        onChange={props.onChangeHandler}
        className={props.additionalClass}
      />
      <label htmlFor={props.id}>{props.labelText}</label>
    </>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  labelText: PropTypes.string,
  additionalClass: PropTypes.string,
  selectedValue: PropTypes.string,
  onChangeHandler: PropTypes.func
};
